/* App.css */

/* Global Styles */
body {
  font-family: 'Arial', sans-serif;
  background-color: #f7f9fc;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  font-size: 1.2rem; /* Increase base font size */
}

.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 90vw; /* Ensure it doesn't overflow */
  max-height: 90vh; /* Ensure it doesn't overflow */
}

/* Start Screen Styles */
.start-screen {
  text-align: center;
  padding: 2rem; /* Increase padding */
}

.start-screen h1 {
  font-size: 2.5rem; /* Increase font size */
  margin-bottom: 2rem; /* Increase margin */
}

.start-screen p {
  font-size: 1.5rem; /* Increase font size */
  margin-bottom: 2rem; /* Increase margin */
}

.start-button {
  background-color: #0288d1;
  border: none;
  border-radius: 5px;
  color: #fff;
  cursor: pointer;
  font-size: 1.5rem; /* Increase font size */
  margin: 1rem;
  padding: 1rem 2rem; /* Increase padding */
  transition: background-color 0.3s;
}

.start-button:hover {
  background-color: #0277bd;
}

/* Quiz Styles */
.quiz-container {
  text-align: center;
  background-color: #fff;
  padding: 2rem; /* Increase padding */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 90vw; /* Ensure it doesn't overflow */
  max-width: 600px; /* Set a max width */
  min-height: 400px; /* Ensure a minimum height */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

h1 {
  color: #333;
  font-size: 2rem; /* Increase font size */
  margin-bottom: 2rem; /* Increase margin */
}

.score-timer {
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;
}

.score,
.timer {
  font-size: 1.5rem; /* Increase font size */
}

.timer {
  color: #d32f2f;
}

.choices {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* Create a 2x2 grid */
  gap: 1rem; /* Add space between the cards */
  justify-items: center; /* Center the cards horizontally */
  align-items: center; /* Center the cards vertically */
  margin-top: 2rem; /* Add margin to create space between feedback and choices */
}

.answer-card {
  width: 100%; /* Set a fixed width */
  height: 100px; /* Set a fixed height */
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 1.5rem; /* Increase font size */
  border-radius: 10px; /* Optional: Add rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional: Add a shadow for better visibility */
}

.answer-card:hover {
  background-color: #b3e5fc;
  transform: scale(1.05);
}

.game-over {
  font-size: 5rem; /* Increase font size */
  color: #e53935;
}

.feedback {
  font-size: 1.5rem; /* Increase font size */
  margin: 1rem 0;
  position: relative; /* Ensure it is positioned relative to its container */
  z-index: 1; /* Ensure it is above other elements */
}

.correct {
  color: green;
}

.incorrect {
  color: red;
}

/* New class for question display */
.consonant-display,
.question-display {
  font-size: 2.5rem; /* Increase font size */
  font-weight: bold;
  margin: 2rem 0; /* Increase margin */
}

.question-word {
  font-size: 2.5rem; /* Increase font size */
  font-weight: bold;
  margin: 2rem 0; /* Increase margin */
}

.hamburger-menu {
  position: absolute;
  top: 1rem; /* Adjust top position */
  right: 1rem; /* Adjust right position */
}

.hamburger-button {
  font-size: 2.5rem; /* Increase font size */
  background: none;
  border: none;
  cursor: pointer;
}

.menu {
  display: flex;
  flex-direction: column;
  background-color: white;
  border: 1px solid #ccc;
  position: absolute;
  top: 4rem; /* Adjust top position */
  right: 1rem; /* Adjust right position */
  z-index: 1000;
}

.menu a {
  padding: 1rem; /* Increase padding */
  text-decoration: none;
  color: black;
}

.menu a:hover {
  background-color: #f0f0f0;
}

/* Contact Page Styles */
.contact-page {
  padding: 2rem; /* Increase padding */
  text-align: center;
}

.contact-page h1 {
  font-size: 2.5rem; /* Increase font size */
  margin-bottom: 2rem; /* Increase margin */
}

.contact-page form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-page label {
  margin-top: 1rem;
}

.contact-page input,
.contact-page textarea {
  width: 100%;
  max-width: 500px; /* Increase max width */
  padding: 1rem; /* Increase padding */
  margin-top: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.contact-page button {
  margin-top: 2rem;
  padding: 1rem 2rem; /* Increase padding */
  background-color: #0288d1;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.contact-page button:hover {
  background-color: #0277bd;
}

/* Add these styles to your existing App.css */

/* Container for the header */
.header-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem; /* Space between the h1 and the image */
}

/* Style for the logo */
.logo {
  width: 75px; /* Adjust the width as needed */
  height: auto; /* Maintain aspect ratio */
}

/* Quiz Container */
.quiz-container {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* Feedback Styles */
.feedback {
  height: 50px;
  margin-bottom: 20px;
}

.feedback.correct {
  color: green;
  border: 2px solid green;
  background-color: #e0ffe0;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
}

.feedback.incorrect {
  color: red;
  border: 2px solid red;
  background-color: #ffe0e0;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
}

.typing-input {
  width: 100%; /* Make the input take the full width of its container */
  padding: 1em; /* Increase padding for a bigger input area */
  font-size: 1.5em; /* Increase font size */
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
}